.root {
	background-color: #eff5ff;
	padding: 80px 0;
	position: relative;

	@media (--medium-up) {
		padding: 160px 0;
	}
}

.container {
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.heading {
	composes: g-type-display-2 from global;
	grid-column: 1 / -1;
	margin: 0 0 48px;
}

.card {
	align-self: flex-start;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / 7;
	}

	@media (--large) {
		align-self: unset;
		grid-column: 1 / 5;
	}

	& > a {
		border: 1px solid #dcdde0;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}

.cardContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 24px;
}

.cardByline {
	align-items: center;
	display: flex;
	margin-bottom: 16px;

	& > * + * {
		margin-left: 12px;
	}
}

.cardBylineAvatar {
	border-radius: 9999px;
	overflow: hidden;
}

.cardBylineName {
	color: var(--black);
}

.cardHeading {
	color: var(--black);
	composes: g-type-display-5 from global;
	margin: 0;
}

.cardDescription {
	color: var(--gray-3);
	composes: g-type-body-small from global;
	margin: 16px 0 0;
}

.cardCta {
	align-items: center;
	color: var(--black);
	display: flex;
	margin-top: auto;
	padding-top: 20px;
}

.cardCtaArrow {
	display: flex;
	margin-left: 12px;
	transform: translate(0);
	transition: transform 0.2s;
	margin-top: 2px;

	@nest .card:hover & {
		transform: translate(2px);
	}
}

.links {
	--columns: 1;

	align-self: flex-start;
	column-gap: 32px;
	display: grid;
	grid-column: 1 / -1;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	list-style: none;
	margin: 16px 0 0;
	padding: 0;

	@media (--medium-up) {
		--columns: 1;

		grid-column: 7 / -1;
		margin-top: -32px;
	}

	@media (--large) {
		--columns: 2;

		grid-column: 5 / -1;
	}

	& > li {
		border-bottom: 1px solid var(--gray-5);
		display: flex;
	}
}

.link {
	color: inherit;
	composes: g-type-display-5 from global;
	width: 100%;
}

.linkInner {
	display: flex;
	padding: 32px 0;
	transition: transform 0.25s ease;

	@nest .link:hover & {
		transform: translate(4px);
	}
}

.linkTitle {
	flex-grow: 1;
}

.linkArrow {
	flex-shrink: 0;
	margin-left: 32px;
	width: 20px;
	height: 20px;
}
