.learnMore {
	composes: g-grid from global;
	composes: g-grid-container from global;
	padding-top: 82px;
	padding-bottom: 82px;

	@media (--medium-up) {
		padding-top: 94px;
		padding-bottom: 94px;
	}
}

.content {
	grid-column: 1 / -1;
	padding-bottom: 36px;

	@media (--medium-up) {
		padding-bottom: 0;
		grid-column: 1 / 6;
	}

	@media (--large) {
		padding-bottom: 0;
		grid-column: 1 / 5;
	}

	& .contentCta {
		padding-top: 24px;

		@media (--medium-up) {
			padding-top: 40px;
		}
	}
}

.heading {
	composes: g-type-display-2 from global;
	margin: 0;
	padding-bottom: 24px;
}

.subheading {
	color: var(--gray-3);
	composes: g-type-body from global;
	margin: 0;
}

.ctas {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 7 / -1;
	}

	@media (--large) {
		grid-column: 6 / -1;
	}
}

.spanBothColumns {
	grid-column: span 2;
}

.themedOfferingDarkTextColor {
	--themed-offering-text-color: var(--black);
}

.themedOfferingLightTextColor {
	--themed-offering-text-color: var(--white);
}

.themedOfferingBackgroundSecondary {
	--themed-offering-background-color: var(--vagrant-secondary);
}

.themedOfferingBackgroundPrimary {
	--themed-offering-background-color: var(--vagrant-logomark);
}

.offeringCtaRow {
	display: grid;
	grid-gap: 16px;
	justify-content: flex-start;
	padding: 0 32px 32px 32px;

	@media (--medium-up) {
		grid-gap: 32px;
		grid-template-columns: max-content max-content;
	}
}

.ctaButton {
	text-align: left;
	align-items: center;
	color: var(--black);
	display: flex;
	font-weight: var(--font-weight-semi-bold);

	&.lightCtaButton {
		color: var(--white);
	}
}

.ctaButtonText {
	composes: g-type-buttons-and-standalone-links from global;
}

.ctaButtonArrow {
	display: flex;
	margin-left: 12px;
	transform: translate(0);
	transition: transform 0.2s;
	margin-top: 2px;

	@nest .themedOfferingBorder:hover & {
		transform: translate(2px);
	}
}

.themedOfferingBorder {
	border-radius: 6px;
}
