.tweetEmbed {
	border: 1px solid var(--gray-5);
	border-radius: 8px;
	padding: 20px;
	max-width: 550px;
	margin: 32px auto;

	& .tweetEmbed {
		margin: 16px auto;
		border-radius: 16px;
	}
}

.author {
	--row-half-gap: 2px;

	display: grid;
	align-items: center;
	grid-template-columns: max-content 1fr max-content;
	grid-template-rows: 1fr 1fr;
	column-gap: 16px;

	& > * {
		line-height: 1;
	}
}

.authorAvatar {
	display: block;
	grid-row: span 2;
	border-radius: 9999px;
	overflow: hidden;
	width: 48px;
	height: 48px;
}

.authorName {
	composes: g-type-body-small-strong from global;
	align-self: end;
	padding-bottom: var(--row-half-gap);
	display: flex;
	align-items: center;
	gap: 4px;
	min-width: 0;

	& a {
		line-height: 1.2;
		color: var(--black);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:hover {
			text-decoration: underline;
		}
	}
}

.verified {
	flex-shrink: 0;
}

.authorUsername {
	composes: g-type-body-small from global;
	grid-row: 2;
	grid-column: 2;
	align-self: start;
	color: var(--wpl-neutral-500);
	padding-top: var(--row-half-gap);
}

.twitterLogo {
	justify-self: end;
	width: 24px;
	height: 24px;
}

.tweetText {
	composes: g-type-body from global;

	& p {
		overflow-wrap: break-word;
	}
}

.attachedMedia {
	padding-bottom: 16px;
	display: grid;

	/* HACK: Prevents unnecessary space below the media */
	& > * {
		display: flex;
	}

	&.media-count-2 {
		grid-template-columns: 1fr 1fr;
		gap: 2px;

		& > :first-child {
			border-radius: 16px 0 0 16px;
		}

		& > :last-child {
			border-radius: 0 16px 16px 0;
		}
	}

	&.media-count-3 {
		aspect-ratio: 16 / 9;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 2px;

		& > :first-child {
			border-radius: 16px 0 0 16px;
			grid-row: span 2;
		}

		& > :nth-child(2) {
			border-radius: 0 16px 0 0;
		}

		& > :last-child {
			border-radius: 0 0 16px 0;
		}
	}

	&.media-count-4 {
		aspect-ratio: 16 / 9;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 2px;

		& > :first-child {
			border-radius: 16px 0 0 0;
		}

		& > :nth-child(2) {
			border-radius: 0 16px 0 0;
		}

		& > :nth-child(3) {
			border-radius: 0 0 0 16px;
		}

		& > :last-child {
			border-radius: 0 0 16px 0;
		}
	}
}

.attachedImage {
	border-radius: 16px;
	overflow: hidden;
}

.attachedVideo {
	border-radius: 16px;
	background: var(--black);
	aspect-ratio: 16 / 9;
	width: 100%;

	&:hover {
		cursor: pointer;
	}
}

.attachedGif {
	position: relative;
	border-radius: 16px;
	overflow: hidden;
	background: var(--black);

	&:hover {
		cursor: pointer;
	}

	& video {
		width: 100%;
	}
}

.gifLabel {
	composes: g-type-label-strong from global;
	position: absolute;
	background: var(--black);
	color: var(--white);
	border-radius: 4px;
	bottom: 16px;
	left: 16px;
	padding: 2px 4px;
}

.timestamp {
	color: var(--wpl-neutral-500);
	display: block;
	width: max-content;
	margin-bottom: 8px;
	transition: 250ms ease;
	transition-property: color;

	&:hover {
		color: var(--wpl-neutral-400);
	}
}

.interactions {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
}

.interaction {
	color: var(--wpl-neutral-500);
	display: flex;
	align-items: center;
	gap: 6px;

	& span,
	& svg path {
		transition: 250ms ease;
	}

	& span {
		transition-property: color;
	}

	& svg path {
		fill: var(--wpl-neutral-500);
		transition-property: fill;
	}

	&:hover {
		& span {
			text-decoration: underline;
		}
	}
}

.replies {
	&:hover {
		& span {
			color: var(--wpl-neutral-400);
		}

		& svg path {
			fill: var(--wpl-neutral-400);
		}
	}
}

.retweets {
	&:hover {
		& span {
			color: var(--wpl-green-400);
		}

		& svg path {
			fill: var(--wpl-green-400);
		}
	}
}

.likes {
	&:hover {
		& span {
			color: var(--wpl-red-400);
		}

		& svg path {
			fill: var(--wpl-red-400);
		}
	}
}

.tweetEmbedFallback {
	composes: tweetEmbed;
	display: grid;
	place-items: center;
}
