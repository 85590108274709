.singleResourceContent {
	display: flex;
	justify-content: center;

	/* 
		Copied from blogs
		https://github.com/hashicorp/web/blob/de80398d0b9335d024f8aead86223b4349c0ba17/apps/www/components/_page-components/blog/post/content/legacy-blog-content/LegacyBlogContent.module.css
	*/
	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		& :global(.__permalink-h) {
			display: none;
		}

		& :global(.__target-h) {
			/* this allows anchor links to have the proper padding */
			display: block;
			height: 4em;
			margin-top: -4em;
			position: relative;
			width: 0;
		}
	}
}

.pageLane {
	border-bottom: 1px solid var(--gray-4);
	composes: pageLane from 'app/[locale]/(site)/(en-only)/resources/[slug]/style.module.css';
	display: flex;
	justify-content: center;
	width: 100%;
}

.longFormContent {
	composes: contentLane from 'app/[locale]/(site)/(en-only)/resources/[slug]/style.module.css';
	margin-bottom: 32px;
	width: 100%;
}
