.root {
	composes: g-grid-container from global;
	padding-bottom: 42px;
}

.logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	padding-left: 0;
	width: 100%;
	margin: 0;

	& li {
		display: grid;
		margin: 0;
		padding: 12px 20px;
		place-items: center;
		text-align: center;
		width: 50%;

		@media (--medium-up) {
			padding: 48px 64px;
			width: calc(1 / 3 * 100%);
		}

		& img {
			height: auto;
			max-height: 100px;
			width: auto;
		}
	}
}
