.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	padding-bottom: 64px;
	padding-top: 64px;

	@media (--medium-up) {
		padding-bottom: 155px;
		padding-top: 155px;
	}
}

.intro {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / 6;
	}
}

.heading {
	composes: g-type-display-2 from global;
	margin: 0;
}

.description {
	composes: g-type-body from global;
	margin: 0;
	padding-bottom: 48px;
	padding-top: 24px;

	@media (--medium-up) {
		padding-bottom: 72px;
	}
}

.outcomesContainer {
	grid-column: 1 / -1;
	padding-top: 52px;

	@media (--medium-up) {
		grid-column: 7 / -1;
		padding-top: 10px;
	}
}

.outcomesHeading {
	composes: g-type-display-3 from global;
	margin: 0;
	padding-bottom: 32px;

	@media (--medium-up) {
		padding-bottom: 40px;
	}
}

.outcomesList {
	display: grid;
	list-style: none;
	margin: 0;
	padding: 0;
	row-gap: 42px;

	@media (--medium-up) {
		row-gap: 80px;
	}

	& .listItem {
		display: flex;

		@media (--medium-up) {
			column-gap: 32px;
			display: grid;
			grid-template-columns: repeat(6, 1fr);
		}
	}
}

.listItemNumber {
	align-items: center;
	background: var(--vagrant-secondary);
	composes: g-type-display-5 from global;
	display: flex;
	flex-shrink: 0;
	height: 32px;
	justify-content: center;
	width: 32px;

	@media (--large) {
		height: 48px;
		width: 48px;
	}
}

.listItemContent {
	padding-left: 24px;

	@media (--medium-up) {
		grid-column: 2 / -1;
		padding-left: 0;
	}
}

.listItemTitle {
	composes: g-type-display-4 from global;
	margin: 0;
}

.listItemDescription {
	composes: g-type-body-small from global;
	margin: 0;
	padding-top: 8px;

	@media (--medium-up) {
		padding-top: 12px;
	}

	& *:first-child {
		margin-top: 0;
	}

	& *:last-child {
		margin-bottom: 0;
	}
}
