.root {
	padding-bottom: 48px;

	@media (--medium-up) {
		background: url('./pattern-top.svg') top right no-repeat;
		padding-bottom: 88px;
		padding-top: 88px;
	}
}

.container {
	composes: g-grid from global;
	composes: g-grid-container from global;
	row-gap: 60px;

	@media (--medium-up) {
		row-gap: 100px;
	}
}

.heading {
	composes: g-type-display-2 from global;
	grid-column: 1 / -1;
	margin: 0;

	@media (--medium-up) {
		grid-column: 1 / 9;
	}

	@media (--large) {
		grid-column: 1 / 7;
	}
}

.itemImage {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / 7;
	}

	@media (--large) {
		grid-column: 1 / 6;
	}
}

.itemContent {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 7 / -1;
	}
}

.itemTitle {
	composes: g-type-display-3 from global;
	margin: 0;
}

.itemSubtitle {
	composes: g-type-body-large from global;
	margin: 0;
	padding-top: 24px;
}

.itemDescription {
	color: var(--gray-2);
	composes: g-body-small from global;
	margin: 0;
	padding-top: 24px;

	& p {
		margin-top: 0;
		margin-bottom: 24px;
		color: var(--gray-2);

		/* copy of g-type-body-small */
		font-family: var(--font-body);
		font-weight: var(--font-weight-reg);
		font-size: 0.938rem;
		letter-spacing: 0.01em;
		line-height: 1.667em;
	}

	& ul {
		padding-left: 16px;
		margin-bottom: 12px;

		& li {
			margin-bottom: 8px;
		}
	}

	/* h5 is the only heading expected here but adding */

	/* styles to all possibilities just in case */
	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		margin-top: 0;
		margin-bottom: 8px;

		/* copy of g-type-body-x-strong */
		font-family: var(--font-body);
		font-weight: var(--font-weight-bold);
		font-size: 1.063rem;
		letter-spacing: 0.01em;
		line-height: 1.647em;
	}
}
