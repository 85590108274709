.videoCallout {
	--columns: 1;

	background-color: var(--black);
	border-radius: 6px;
	display: grid;
	gap: 32px;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	margin: 0;
	overflow: hidden;

	@media (--medium-up) {
		--columns: 12;
	}
}

.thumbnail {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: grid;
	grid-column: 1 / -1;
	min-height: 300px;
	padding: 96px 32px;
	place-items: center;
	position: relative;

	@media (--medium-up) {
		grid-column: 1 / 7;
	}

	@media (--large) {
		grid-column: 1 / 9;
	}

	& > svg {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		@media (--small) {
			height: 52px;
			width: 52px;
		}
	}

	&::after {
		background-color: #000;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0.45;
		position: absolute;
		top: 0;
		transition: opacity ease-in-out 0.2s;
		width: 100%;
	}

	&:hover::after {
		opacity: 0.2;
	}
}

.content {
	grid-column: 1 / -1;
	padding: 32px;

	@media (--medium-up) {
		grid-column: 7 / -1;
		padding: 80px 32px;
	}

	@media (--large) {
		grid-column: 9 / -1;
	}
}

.heading {
	color: var(--white);
	composes: g-type-display-4 from global;
	margin: 0;
}

.description {
	color: var(--white);
	composes: g-type-body-small from global;
	margin: 8px 0 0;
}

.person {
	align-items: center;
	display: flex;
	gap: 16px;
	margin-top: 64px;
}

.personAvatar {
	border-radius: 9999px;
	display: flex;
	overflow: hidden;
	flex-shrink: 0;
}

.personName {
	color: var(--white);
	composes: g-type-body-strong from global;
	margin: 0;
}

.personDescription {
	color: var(--gray-3);
	composes: g-type-label-strong from global;
	margin: 4px 0 0;
}

.videoHeading {
	composes: g-type-display-4 from global;
	margin-bottom: 32px;
	margin-top: 0;
	padding-right: 100px;
}

.video {
	aspect-ratio: 16 / 9;
	background-color: var(--gray-2);
	position: relative;
}
