.root {
	background-image: url('./pattern.svg');
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: 300px;
	padding-bottom: 50px;
	position: relative;

	@media (--small) {
		background-position: bottom right -50px;
	}

	& > * {
		background-color: transparent;
	}
}
