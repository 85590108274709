.blogPostSocialShare {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;

	& li {
		position: relative;
		display: grid;
		place-items: center;
	}
}

.shareButton {
	width: 24px;
	height: 24px;
	background: none;
	appearance: none;
	border: none;
	padding: 0;
	margin: 0;

	& svg path {
		fill: var(--wpl-neutral-300);
		transition: 250ms ease;
		transition-property: fill;
	}

	&:hover {
		cursor: pointer;

		& svg path {
			fill: var(--wpl-neutral-600);
		}
	}
}

.tooltip {
	composes: g-type-body-small from global;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 2px;
	padding: 2px 8px;
	border-radius: 4px;
	position: absolute;
	top: -24px;
	background-color: var(--gray-2);
	color: var(--white);
}
