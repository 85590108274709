.root {
	background: var(--gray-6);
	padding: 48px 0;

	@media (--medium-up) {
		padding: 88px 0;
	}
}

.container {
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.heading {
	composes: g-type-display-2 from global;
	grid-column: 1 / -1;
	margin: 0;
	padding-bottom: 32px;

	@media (--medium-up) {
		grid-column: 1 / 9;
		padding-bottom: 56px;
	}

	@media (--large) {
		grid-column: 1 / 7;
	}
}

.items {
	--columns: 1;

	column-gap: 32px;
	display: grid;
	grid-column: 1 / -1;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	list-style: none;
	margin: 0;
	padding: 0;
	row-gap: 32px;

	@media (--medium-up) {
		--columns: 3;
	}

	& > li {
		display: flex;
	}
}

.card {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: center;

	& > a {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}

.cardInner {
	background-color: var(--white);
	border: 1px solid var(--gray-6);
	border-radius: 1px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	transition: box-shadow 0.25s, transform 0.25s;

	@nest .card:hover & {
		box-shadow: 0 16px 28px rgb(37 38 45 / 12%);
		transform: translateY(-4px);
	}
}

.cardBody {
	margin-bottom: auto;
	padding: 24px;

	@media (--medium-up) {
		padding: 32px;
	}
}

.cardHeading {
	color: var(--black);
	composes: g-type-display-4 from global;
	margin: 24px 0 0;
}

.cardDescription {
	color: var(--gray-2);
	composes: g-type-body from global;
	margin: 16px 0 0;

	@media (--medium-up) {
		margin-top: 24px;
	}
}

.cardFooter {
	align-items: center;
	border-top: 1px solid var(--gray-6);
	color: var(--gray-2);
	display: flex;
	justify-content: center;
	margin-top: 24px;
	padding: 16px;

	@media (--medium-up) {
		margin-top: 32px;
	}
}

.cardFooterArrow {
	margin-left: 12px;
	margin-top: 2px;
}
