.container {
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.inner {
	background-color: var(--white);
	box-shadow: 0 8px 12px rgba(37, 38, 45, 0.08);
	grid-column: 1 / -1;
	padding: 48px 24px;
	text-align: center;

	@media (--medium-up) {
		grid-column: 2 / 12;
		padding: 100px;
	}
}

.text {
	composes: g-type-display-3 from global;
	margin: 16px 0 0;
}

.source {
	composes: g-type-label from global;
	margin: 48px 0 0;

	& .title {
		margin-top: 8px;
		color: var(--gray-3);
	}
}
