.root {
	background-image: linear-gradient(
		to bottom,
		var(--white) calc(50% - 88px),
		#eff5ff calc(50% - 88px),
		var(--white) 100%
	);
	padding-bottom: 88px;

	@media (--medium-up) {
		padding-bottom: 154px;
	}
}
