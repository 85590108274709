.root {
	padding: 48px 0 0;

	@media (--medium-up) {
		padding: 128px 0 0;
	}
}

.container {
	composes: g-grid from global;
	composes: g-grid-container from global;
}

.heading {
	composes: g-type-display-2 from global;
	grid-column: 1 / -1;
	margin: 0;
	padding-bottom: 24px;

	@media (--medium-up) {
		grid-column: 1 / 7;
	}
}

.left,
.right {
	grid-column: 1 / -1;

	& > * {
		margin-top: 0;
		margin-bottom: 16px;

		@media (--medium-up) {
			margin-bottom: 32px;
		}
	}

	& ul {
		padding-left: 16px;

		& li {
			margin-bottom: 8px;
		}
	}
}

.left {
	@media (--medium-up) {
		grid-column: 1 / 7;
	}
}

.right {
	@media (--medium-up) {
		grid-column: 7 / -1;
	}
}
