.partners {
	padding-bottom: 0 !important;
	padding-top: 82px !important;

	@media (--medium-up) {
		padding-top: 94px !important;
	}

	& h2 {
		/* copy of g-type-display-2 from global */

		/* use this selector to override global component heading styles */

		/* cannot compose class using this selector */
		font-family: var(--font-display);
		font-weight: var(--font-weight-bold);
		font-size: 1.75rem;
		letter-spacing: -0.004em;
		line-height: 1.286em;

		@media (--medium-up) {
			font-size: 2.125rem;
			letter-spacing: -0.008em;
			line-height: 1.265em;
		}

		@media (--large) {
			font-size: 2.5rem;
			letter-spacing: -0.01em;
			line-height: 1.25em;
		}
	}
}
