.offeringGrid {
	--gutter: 32px;
	--themed-offering-background-color: #fafafa;

	display: grid;
	flex-direction: column;
	grid-gap: var(--gutter);
	list-style: none;
	margin: 0;
	padding: 0;
	@media (--large) {
		grid-template-columns: 1fr 1fr;
	}
}

.offering {
	background: var(--themed-offering-background-color);
	border: 1px solid var(--themed-offering-background-color, var(--gray-6));
	box-sizing: border-box;
	display: grid;
	margin: 0;
}

.offeringLinkWrapper {
	display: grid;
	grid-template-rows: 1fr auto;
}

.content {
	color: var(--themed-offering-text-color, var(--black));
	display: grid;
	grid-template-rows: auto 1fr;
	padding: 32px;
}

.titleBar {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 14px;
}

.infoBar {
	align-items: center;
	display: grid;
	grid-gap: 8px;
	justify-content: flex-start;
}

.title {
	composes: g-type-display-4 from global;
}

.subtitle {
	composes: g-type-display-6 from global;
	margin-bottom: 4px;
	margin-top: 0;
}

.price {
	composes: g-type-display-6 from global;
	margin: 0;
}

.pricePrefix {
	composes: g-type-label from global;
	text-transform: uppercase;
}
