.singleResourcePage {
	composes: g-grid-container from global;
}

.pageLane {
	@media (--large) {
		max-width: 904px;
	}
}

.contentLane {
	@media (--large) {
		max-width: 736px;
	}
}
