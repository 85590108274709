.longformContentSection {
	/*
   *
   * NOTE:
   *
   * We have an ongoing project to establish consistency in long form content styles.
   * It seems likely we will never have FULL consistency, and will instead do
   * something like the above, where we share styles for some elements, but deviate
   * on others.
   *
   * Asana project:
   * https://app.asana.com/0/1199989078872420/list
   *
   *
   * -------------------------------------------------------
   *
   * Aim in the context of this component would likely be to clean up the below
   * styles to make it more clear what is being overriden. See also this
   * task on headings: https://app.asana.com/0/1100423001970639/1200632621534164/f
   *
   * Deviations for "LongFormContentSection" 
   * (compared to @hashicorp/react-content)
   *
   * - headings
   *   !! - These are actually mostly powered by GLOBAL STYLES.
   *   We likely want to localize these rather than ship them globally,
   *   as they are largely duplicative of our g-type styles. Compared with
   *   the otherwise consistently used @hashicorp/react-content component,
   *   Blog essentially just changes the mapping of g-type styles to heading elements.
   *   Task to address this:
   *   https://app.asana.com/0/1100423001970639/1200632621534164/f
   *
   * - paragraph text
   *   Just slightly larger, darker, and spaced out here on www-next
   *   compared to in @hashicorp/react-content, which is in use on Docs and Learn.
   *
   * - images
   *   some modifications to make large images bump out of the content area
   *   on larger screens. Maybe appropriate to handle via a custom MDX component,
   *   or by fully migratin to use Dato's modular content fields for Blog content.
   *   
   * -------------------------------------------------------
   * 
   * [Note from original implementation of the blog styles below]
   *
   * Some slight variations have been made to match our new spec.
   * The likely scenario is that our longform content changes are fed back into our web
   * components. It would be nice to to this before blog ships, but in the event we can't
   * it will be marked as higher priority debt to tackle.
   *
   */

	/* Set same default color as g-content */
	color: var(--gray-2);

	/* Base typography */
	composes: g-type-body-long from global;

	/* Compose @hashicorp/react-content styles that we want */
	composes: a from '@hashicorp/react-content/styles/a.module.css';

	/* Note that even though __permalink-h elements are rendered, styles are
  NOT included, as we don't want to show permalinks on blog. */
	composes: blockquote from '@hashicorp/react-content/styles/blockquote.module.css';
	composes: hr from '@hashicorp/react-content/styles/hr.module.css';
	composes: olUlLists from '@hashicorp/react-content/styles/ol-ul-lists.module.css';

	/* Set blog-specific font metrics */
	font-size: 17px;
	line-height: 30px;
	word-break: break-word;

	& > picture > img,
	& > img {
		--large-image-margin: -89px;

		display: block;
		margin-bottom: 32px;
		margin-left: var(--large-image-margin);
		margin-right: var(--large-image-margin);
		margin-top: 32px;
		width: calc(100% + (-2 * var(--large-image-margin)));

		@media (max-width: 975px) {
			--medium-image-margin: -40px;

			margin-bottom: 24px;
			margin-left: var(--medium-image-margin);
			margin-right: var(--medium-image-margin);
			margin-top: 24px;
			width: calc(100% + (-2 * var(--medium-image-margin)));
		}

		@media (max-width: 700px) {
			margin-left: auto;
			margin-right: auto;
			width: 100%;
		}
	}

	/* hr - margin adjust only */
	& hr {
		margin: 48px 0;

		@media (max-width: 975px) {
			margin: 32px 0;
		}
	}

	/* Table */
	& > table {
		margin: 32px 0;
		word-break: normal;
	}

	/* Headings */
	& > h1 {
		margin-bottom: 12px;

		/* TODO, no spec provided */
		margin-top: 48px;

		@media (max-width: 975px) {
			margin-top: 40px;
		}

		@media (max-width: 700px) {
			margin-top: 32px;
		}
	}

	& > h2 {
		margin-bottom: 12px;

		/* TODO, no spec provided */
		margin-top: 48px;

		@media (max-width: 975px) {
			margin-top: 40px;
		}

		@media (max-width: 700px) {
			margin-top: 32px;
		}
	}

	& > h3 {
		margin-bottom: 12px;
		margin-top: 48px;

		@media (max-width: 975px) {
			margin-top: 40px;
		}

		@media (max-width: 700px) {
			margin-top: 32px;
		}
	}

	& > h4 {
		margin-bottom: 12px;
		margin-top: 32px;

		@media (max-width: 700px) {
			margin-top: 24px;
		}
	}

	& > h5 {
		margin-bottom: 8px;
		margin-top: 24px;

		@media (max-width: 700px) {
			margin-bottom: 4px;
			margin-top: 16px;
		}
	}

	& > h6 {
		margin-bottom: 4px;
		margin-top: 24px;

		@media (max-width: 700px) {
			margin-top: 12px;
		}
	}

	/* Paragraph text */
	& > p {
		letter-spacing: 0.0125em;
		margin-bottom: 20px;
		margin-top: 4px;
	}

	/* pre+code blocks without syntax highlighting */
	& > pre:not([class]) {
		background-color: #0d0e12;
		border-radius: 6px;
		margin: 32px 0;
		padding: 16px;
		overflow: auto;
		color: #d5d7db;
		font-family: var(--token-typography-code-200-font-family);
		font-weight: var(--token-typography-font-weight-regular);
		font-size: 0.8125rem;
		line-height: 1.4286;

		& code {
			font: inherit;
			line-height: inherit;
			color: inherit;
			overflow-wrap: normal;
			white-space: pre;
			padding: 0;
		}
	}
}
